<template>
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
      no-fade
    >
      <b-card
        class="mb-4"
      >
        <b-card-header>
          <h3>Period
            <b-spinner
              v-if="periodId>0 && periodData.name === ''"
              class="ml-2"
              label="Loading..."
            />
          </h3>
        </b-card-header>
        <b-card-body>
          <validation-observer ref="simplePeriods">
            <b-form @submit.prevent>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Name"
                    label-for="h-name"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <b-form-input
                        v-if="periodData.can_edit_or_delete===true"
                        v-model="periodData.name"
                        placeholder="name"
                        :maxlength="30"
                      />
                      <b-form-input
                        v-if="periodData.can_edit_or_delete===false"
                        v-model="periodData.name"
                        placeholder="name"
                        readonly="readonly"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-form-group
                    label="Period Start"
                    label-for="h-p-start"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Period Start"
                      rules="required"
                    >
                      <b-input-group class="mb-1">
                        <b-form-input
                          id="period_start"
                          v-model="periodData.period_start"
                          type="text"
                          placeholder="YYYY-MM-DD"
                          autocomplete="off"
                          show-decade-nav
                          readonly="readonly"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="periodData.period_start"
                            :disabled="!periodData.can_edit_or_delete"
                            show-decade-nav
                            button-only
                            right
                            locale="en-US"
                            aria-controls="period_start"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small class="text-danger">{{ errorsMessage }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-form-group
                    label="Period End"
                    label-for="h-p-end"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Period End"
                      rules="required"
                    >
                      <b-input-group class="mb-1">
                        <b-form-input
                          id="period_end"
                          v-model="periodData.period_end"
                          type="text"
                          placeholder="YYYY-MM-DD"
                          autocomplete="off"
                          show-decade-nav
                          readonly="readonly"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="periodData.period_end"
                            :disabled="!periodData.can_edit_or_delete"
                            show-decade-nav
                            button-only
                            right
                            locale="en-US"
                            aria-controls="period_end"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="periodId > 0 && periodData.can_edit_or_delete===true"
                  cols="12"
                >
                  <b-form-group
                    label="Status"
                    label-for="h-status"
                    label-cols-md="4"
                  >
                    <b-form-select
                      v-model="periodData.period_status"
                      :options="period_status_options"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="periodId > 0 && periodData.can_edit_or_delete===false"
                  cols="12"
                >
                  <b-form-group
                    label="Status"
                    label-for="h-status"
                    label-cols-md="4"
                  >
                    <b-form-select
                      v-model="periodData.period_status"
                      :options="period_status_options"
                      disabled="disabled"
                    />
                  </b-form-group>
                </b-col>

                <!-- submit and reset -->
                <b-col offset-md="4">
                  <b-button
                    v-if="periodId>0 && periodData.can_edit_or_delete===true"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click.prevent="validationForm"
                  >
                    Update
                  </b-button>
                  <b-button
                    v-if="periodId>0 && periodData.can_edit_or_delete===false"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                    @click="closeSidebar()"
                  >
                    Close
                  </b-button>
                  <b-button
                    v-if="periodId==0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click.prevent="validationForm"
                  >
                    Add
                  </b-button>
                </b-col>
              </b-row>

            </b-form>
          </validation-observer>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BCardBody, BCardHeader,
  BOverlay, BSpinner, BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import store from '@/store'
import { referencePeriods } from '@/views/pages/reference-period/referencePeriods'

import {
  required,
} from '@validations'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  name: 'PeriodSidebar',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BOverlay,
    BSpinner,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: ['isRuleSidebarActive', 'periodId'],
    event: 'update:is-period-sidebar-active',
  },
  props: {
    isPeriodSidebarActive: {
      type: Boolean,
      required: true,
    },
    periodId: {
      type: Number,
      required: true,
      default: () => 0,
    },
  },
  data() {
    return {
      show: true,
      errorsMessage: '',
      required,
      periodData: {
        name: '',
        period_start: '',
        period_end: '',
        can_edit_or_delete: true,
        period_status: 'A',
      },
      period_status_options: [
        { value: 'A', text: 'Active' },
        { value: 'I', text: 'Inactive' },
      ],
    }
  },
  watch: {
    // eslint-disable-next-line
    getPeriod(periodId) {
      if (periodId) {
        this.show = true
        this.periodData = {}
        const { fetchReferencePeriods } = referencePeriods()
        fetchReferencePeriods(periodId)
          .then(response => {
            this.periodData = response.data.data
            this.periodData.period_start = this.formatDate(this.periodData.period_start)
            this.periodData.period_end = this.formatDate(this.periodData.period_end)
            this.show = false
          })
      }
    },
  },
  mounted() {
    this.show = false
    if (this.periodId > 0) {
      this.ruleData = {}
      this.periodData.can_edit_or_delete = false
      const { fetchPeriod } = referencePeriods()
      fetchPeriod(this.periodId)
        .then(response => {
          this.periodData = response.data.data
          this.periodData.period_start = this.formatDate(this.periodData.period_start)
          this.periodData.period_end = this.formatDate(this.periodData.period_end)
          this.show = false
        })
    }
  },
  methods: {
    closeSidebar() {
      this.$emit('update:is-period-sidebar-active', false)
      this.$emit('update:refetch-data', true)
      this.show = false
    },
    formatDate(date) {
      return new Date(date).toISOString().slice(0, 10)
    },
    onClickChild(value) {
      this.$emit('clicked', value)
    },
    validationForm() {
      this.errorsMessage = ''
      this.$refs.simplePeriods.validate().then(success => {
        if (Date.parse(this.periodData.period_start) > Date.parse(this.periodData.period_end)) {
          this.errorsMessage = 'Period start and Period End date ranges are invalid'
        } else if (success) {
          this.show = true
          const { saveReferencePeriod } = referencePeriods()
          saveReferencePeriod(this.periodData).then(response => {
            if (this.periodId === 0) {
              store.commit('rules-reference-periods/UPDATE_REFERENCE_PERIOD', {
                value: response.data.data.id,
                text: response.data.data.name,
                period_start: this.formatDate(response.data.data.period_start),
                period_end: this.formatDate(response.data.data.period_end),
              })
            }
            this.$emit('update:is-period-sidebar-active', false)
            this.$emit('update:refetch-data', true)
            this.show = false
            // this.$emit('refetch-data', true)
            this.periodData = {}
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-down',
              props: {
                title: 'Save Period',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Period information saved successfully',
              },
            })

            this.$emit('clicked', true)
          }).catch(error => {
            let text = ''
            if (error.response) {
              text = error.response.data.detail
              // client received an error response (5xx, 4xx)
            } else {
              text = error.message
              // eslint-disable-next-line no-console
              console.log(text)
            }
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-down',
              props: {
                title: 'Save Period',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text,
              },
            })
            this.show = false
          })
        }
      })
    },
  },
}
</script>
